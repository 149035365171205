import React from "react"
import Clamp from "components/clamp"
import Hero from "components/hero"
import News from "components/news"

export default () => {
  return (
    <>
      <Hero>GPI Capital Announces Promotions</Hero>
      <Clamp inset>
        <h1>In the news</h1>
        <News />
      </Clamp>
    </>
  )
}
