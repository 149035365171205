import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function useNews(quantity) {
  const [state, setState] = React.useState(null)

  const query = useStaticQuery(graphql`
    query NewsQuery {
      allNewsJson(sort: { fields: date, order: DESC }) {
        nodes {
          id
          date(formatString: "MMMM D, YYYY")
          title
          href
          source
        }
      }
    }
  `).allNewsJson.nodes

  React.useEffect(() => {
    setState(quantity ? query.slice(0, quantity) : query)
  }, [query, quantity])

  return state
}
