import React from "react"
import useNews from "hooks/use-news"
import * as styles from "./news.module.less"

export default function News({ quantity }) {
  const data = useNews(quantity)

  return (
    data &&
    React.Children.toArray(
      data.map(i => (
        <div className={styles.root}>
          <div className={styles.head}>
            <a className={styles.link} href={i.href}>
              {i.title}
            </a>
          </div>
          <div className={styles.from}>
            {i.date}&ensp;({i.source})
          </div>
        </div>
      ))
    )
  )
}
